import { createFileRoute, redirect } from '@tanstack/react-router';
import i18n from '@locale/i18n';

export const Route = createFileRoute('/account/')({
    beforeLoad: async ({ context }) => {
        if (!context.user.loggedIn) {
            if (!i18n.hasLoadedNamespace('links')) {
                await i18n.loadNamespaces('links');
            }
            throw redirect({
                to: i18n.t('account.login.absolute', { ns: 'links' }),
                replace: true,
            });
        }
    },
});
